import Vue from 'vue';
import Vuetify from 'vuetify/lib';


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#92898a",
        secondary: "#764b5e",
        accent: "#FFFFFF",
        info: "#FBC02D",
        success: "#78909C",
        error: "#EF5350",
        warning: "#651FFF",
        background: "#FFF"
      }
    }
  }
});
