<template>
  <div class="home">
    <JustChurch msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import JustChurch from "@/components/JustChurch.vue";

export default {
  name: "Home",
  components: {
    JustChurch
  }
};
</script>
