<template>
  <div class="bg">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-container>
            <v-card
              class="mx-auto text-center"
              max-width="600"
              color="rgba(255, 255, 255, 0.7)"
            >
              <v-card-text>
                <h1 class="display-2 py-3 primary--text">
                  Welcome to Just Church
                </h1>
              </v-card-text>
              <v-card-text>
                <p class="body-1 font-weight-regular secondary--text">
                  Our vision is "to know him, be known by him and to make him known". 
                </p>
                <v-img
                  alt="Chanthorn and Jessica Hor"
                  class="mr-3 mt-5"
                  src="https://d2jr1lht6rcaaw.cloudfront.net/justchurch/family.jpg"
                  transition="scale-transition"
                  :width="$vuetify.breakpoint.mdAndUp ? 650 : 550"
                />
                <p class="body-1 font-weight-regular secondary--text">
                  Chanthorn and Jessica Hor are Pastor's at <strong>Just Church</strong>, and they are passionate about seeing people come to know Jesus and to know themselves in him. 
                </p>
                <p class="body-1 font-weight-regular secondary--text">
                  "My sheep hear my voice, and I know them, and they follow me. I give them eternal life, and they will never perish, and no one will snatch them out of my hand" (John 10:27-28).
                </p>
                <!-- <p class="body-1 font-weight-regular secondary--text">
                  We believe in the power of his presence through worship and hosting his presence and being in his presence.
                </p> -->
                <p class="body-1 font-weight-regular secondary--text">
                  At Just Church we believe that if people really got to know Jesus, we cannot help but to love him because he is love. 
                </p>
                <p class="body-1 font-weight-regular secondary--text">
                  We also believe that the church is a congregation of fellow believers, and that God can call any one of us to witness and to lead with the gifts that he bestows on us through the Holy Spirit. <strong>Lord Jesus</strong> assures us that "where two or three gather in my name, there am I with them" (Matthew 18:20). That's why we pray and endeavour to wait that <strong>Jesus</strong> will lead us in worship when we meet as a congregation. 
                </p>
                <p class="title secondary--text">
                   Jesus is the High Priest, he is the Lord of all and he is the only true Teacher of his disciples. <br/> <span class="body-1 font-weight-regular secondary--text">Refer Hebrews 4:14-16, John 20:28, Matthew 23:10</span>
                </p>
                <!-- <p class="body-1 font-weight-regular secondary--text">
                  They are hard at work raising four sons in between ministry and working. Praise be to God for all the amazing blessings he bestows 🙏✨
                </p> -->
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <br /><br /><br />
        <v-col cols="12">
          <v-container>

            <v-card
              class="mx-auto text-center"
              max-width="600"
              color="rgba(255, 255, 255, 0.5)"
            >
              <v-card-text>
                <h1 class="display-1 py-3 primary--text">
                  About Just Church
                </h1>
              </v-card-text>
              <v-card-text>
                <p class="body-1 font-weight-regular secondary--text">
                  So where did the inspiration come from to name our congregation <strong>Just Church</strong>?
                </p>
                <p class="body-1 font-weight-regular secondary--text">
                   When we started the Church we really wanted a name that described who we are as a people and our heart. Coincidentally, it's the initials of our Saviour <strong>Jesus Christ 💖 Just Church</strong> 
                </p>
                <p class="body-1 font-weight-regular secondary--text">
                  It really is a place where everyone is welcome. Come as you are, it's <strong>Just Church</strong>. We're no frills, there's no fuss, it's <b>Just Church</b>. A group of people who love <strong>Jesus</strong> gathering together to worship.
                </p>
                <p class="body-1 font-weight-regular secondary--text">
                  The church is part of the mystery of God: lowly and humble, available to all who seek God right where they are in life. Like Jesus said: "I praise you, Father, Lord of heaven and earth, because you have hidden these things from the wise and learned, and revealed them to little children" (Matthew 11:25). 
                </p>
                <p class="body-1 font-weight-regular secondary--text">
                  Come check us out, you'll find it's <strong>Just Church</strong>. There's really nothing to worry about it, it's <b>Just Church</b>. What denomination are you? We're <b>Just Church</b>! Stripping the barriers and the negative connotations away from what people believe the Church to be and simply saying we're <b>Just Church</b>.
                </p>
                <v-img
                  alt="Praying, Just Church"
                  class="mr-3 mt-5"
                  src="https://d2jr1lht6rcaaw.cloudfront.net/justchurch/pray2.jpg"
                  transition="scale-transition"
                  :width="$vuetify.breakpoint.mdAndUp ? 650 : 550"
                />
                

              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <br /><br /><br />
        <v-col cols="12">
          <v-container>

            <v-card
              class="mx-auto text-center"
              max-width="600"
              color="rgba(255, 255, 255, 0.5)"
            >
              <v-card-text>
                <h1 class="display-1 py-3 primary--text">
                  Reaching out locally and globally
                </h1>
              </v-card-text>
              <v-card-text>
                <p class="body-1 font-weight-regular secondary--text">
                  We are also a Mission's church, we have links in Cambodia and go nearly every two years to build wells and toilets in different villages.
                </p>
                <p class="body-1 font-weight-regular secondary--text">
                  While helping to financially support a Pastor in one of the local villages to enable him to continue sharing the gospel. It's a hard life there, there's no government assistance and working the land is hard labour and doesn't pay well. 
                </p>
                <v-img
                  alt="Reaching out locally and globally"
                  class="mr-3 mt-5"
                  src="https://d2jr1lht6rcaaw.cloudfront.net/justchurch/joy.jpg"
                  transition="scale-transition"
                  :width="$vuetify.breakpoint.mdAndUp ? 650 : 550"
                />

                <p class="body-1 font-weight-regular secondary--text">
                  "I tell you, use worldly wealth to gain friends for yourselves, so that when it is gone, you will be welcomed into eternal dwellings." Luke 16:9 
                </p>
                <p class="body-1 font-weight-regular secondary--text">
                  This verse tells us to use our money to gain friends in heaven you know, money is material and is here one day gone the next but if we have it, investing it in other's and those God has called is Kingdom.
                </p>
              </v-card-text>
            </v-card>

          </v-container>
        </v-col>
        <br /><br /><br />
      </v-row>
  </div>
</template>

<script>
  export default {
    name: 'JustChurch',

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>
<style lang="scss" scoped>
.bg {
  background-image: url("https://d2jr1lht6rcaaw.cloudfront.net/justchurch/beach.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>