import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store"

// Amplify
import Amplify, * as AmplifyModules from "aws-amplify";
import { Auth, Logger } from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

Amplify.Logger.LOG_LEVEL = "NONE";

const logger = new Logger("main");

Auth.currentAuthenticatedUser()
  .then(user => logger.debug(user))
  .catch(err => logger.debug(err));

Vue.use(AmplifyPlugin, AmplifyModules, Auth);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  data: {color: '#673AB7'},
  render: h => h(App)
}).$mount("#app");
