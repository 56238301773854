<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <Navbar />
    <transition name="fade">
      <router-view />
    </transition>
    <NavFooter />
  </v-app>
</template>

<script>
import Navbar from "@/components/NavBar";
import NavFooter from "@/components/NavFooter";

export default {
  name: "App",
  components: {
    Navbar,
    NavFooter
  },
  data: () => ({
    //
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  }
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: all;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: translateX(-25px);
}
</style>
