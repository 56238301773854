<template>
  <div>
    <!-- Side Navbar (mobile apps) -->
    <v-navigation-drawer app temporary v-model="sideNav">
      <v-toolbar>
        <v-app-bar app color="primary">
          <router-link to="/" tag="span" style="cursor: pointer">
            <div class="d-flex align-center">
              <v-img
                alt="Just Church"
                src="https://d2jr1lht6rcaaw.cloudfront.net/justchurch/logo.png"
                transition="scale-transition"
                width="65"
              />
            </div>
          </router-link>
        </v-app-bar>
      </v-toolbar>
      <v-divider></v-divider>
      <!-- Side Navbar links -->
      <v-list>
        <v-list-item-group color="secondary">
          <v-list-item
            v-for="item in NavItems"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-content>{{ item.title }}</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Horizontal Navbar -->
    <v-app-bar :prominent="$vuetify.breakpoint.mdAndUp ? true : false">
      <v-app-bar-nav-icon
        @click="toggleSideNav"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <div class="headline d-none d-md-none d-md-flex">
          <router-link to="/">
            <v-img
              alt="Just Church"
              class="mr-3 mt-5"
              src="https://d2jr1lht6rcaaw.cloudfront.net/justchurch/logo_black.png"
              transition="scale-transition"
              :width="$vuetify.breakpoint.mdAndUp ? 140 : 140"
            />
          </router-link>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="d-none d-sm-flex">
        <v-btn
          v-for="item in NavItems"
          :key="item.title"
          :to="item.link"
          class="pt-8"
          text
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    sideNav: false,
    userName: "",
    userId: "",
    NavItems: [
      { title: "Home", link: "/" },
      { title: "Photos", link: "/photos" },
      { title: "Contact", link: "/contact" },
      { title: "Blog", link: "/blog" }
    ]
  }),
  methods: {
    toggleSideNav() {
      this.sideNav = !this.sideNav;
    }
  }
};
</script>

<style lang="scss">
.v-toolbar__content {
  padding-top: 0 !important;
}

</style>
