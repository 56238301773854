/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/photos",
    name: "Photos",
    component: () => import("../components/Photos")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import( "../views/Contact.vue")
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../components/SignIn")
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../components/SignUp")
  },
  {
    path: "/members",
    name: "members",
    component: () => import("../components/MembersOnly"),
    meta: { requiresAuth: true }
  },
  {
    path: "/blog",
    name: "listblogs",
    component: () => import("../views/Blog")
  },
  {
    path: "/blog-admin",
    name: "blog-admin",
    component: () => import("../views/BlogAdmin"),
    meta: { requiresAuth: true }
  },
  {
    path: "/new-blog",
    name: "new-blog",
    component: () => import("../views/BlogNew"),
    meta: { requiresAuth: true }
  },
  {
    path: "/author/:anchor",
    name: "authorViewBlog",
    component: () => import("../views/BlogPreview"),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/admin/:anchor",
    name: "adminViewBlog",
    component: () => import("../views/BlogAdminPreview"),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/blog/:anchor",
    name: "viewBlog",
    component: () => import("../views/BlogView"),
    props: true
  },
  {
    path: "/author/edit/:anchor",
    name: "authorEditBlog",
    component: () => import("../views/BlogEdit"),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/reset-password",
    name: "resetpw",
    component: () => import("../components/ResetPassword")
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/Admin"),
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },

  base: process.env.BASE_URL,
  routes
});

router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let user;
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
      .then(data => {
        if (data && data.signInUserSession) {
          user = data;
        }
        next();
      }).catch((e) => {
        next({
          path: "/account",
          query: {
            redirect: to.fullPath
          }
        });
      });
  }
  else next();
});

export default router;
