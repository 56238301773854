/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:fbad0b4d-90b7-4c94-b952-1469ad15869f",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_VAxsE5lxO",
    "aws_user_pools_web_client_id": "e5b570vte9t50v4gmout45m5i",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://c7fgepuepfazjjldew2qal4vhe.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
